(function (factory) {
    typeof define === 'function' && define.amd ? define(['kendo.core'], factory) :
    factory();
})((function () {
    (function( window, undefined$1 ) {
        kendo.cultures["ar-AE"] = {
            name: "ar-AE",
            numberFormat: {
                pattern: ["-n"],
                decimals: 2,
                ",": ",",
                ".": ".",
                groupSize: [3],
                percent: {
                    pattern: ["-n%","n%"],
                    decimals: 2,
                    ",": ",",
                    ".": ".",
                    groupSize: [3],
                    symbol: "%"
                },
                currency: {
                    name: "United Arab Emirates Dirham",
                    abbr: "AED",
                    pattern: ["-n $","n $"],
                    decimals: 2,
                    ",": ",",
                    ".": ".",
                    groupSize: [3],
                    symbol: "د.إ.‏"
                }
            },
            calendars: {
                standard: {
                    days: {
                        names: ["الأحد","الإثنين","الثلاثاء","الأربعاء","الخميس","الجمعة","السبت"],
                        namesAbbr: ["الأحد","الإثنين","الثلاثاء","الأربعاء","الخميس","الجمعة","السبت"],
                        namesShort: ["ح","ن","ث","ر","خ","ج","س"]
                    },
                    months: {
                        names: ["يناير","فبراير","مارس","أبريل","مايو","يونيو","يوليو","أغسطس","سبتمبر","أكتوبر","نوفمبر","ديسمبر"],
                        namesAbbr: ["يناير","فبراير","مارس","أبريل","مايو","يونيو","يوليو","أغسطس","سبتمبر","أكتوبر","نوفمبر","ديسمبر"]
                    },
                    AM: ["ص","ص","ص"],
                    PM: ["م","م","م"],
                    patterns: {
                        d: "dd/MM/yyyy",
                        D: "dd MMMM, yyyy",
                        F: "dd MMMM, yyyy hh:mm:ss tt",
                        g: "dd/MM/yyyy hh:mm tt",
                        G: "dd/MM/yyyy hh:mm:ss tt",
                        m: "dd MMMM",
                        M: "dd MMMM",
                        s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                        t: "hh:mm tt",
                        T: "hh:mm:ss tt",
                        u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                        y: "MMMM, yyyy",
                        Y: "MMMM, yyyy"
                    },
                    "/": "/",
                    ":": ":",
                    firstDay: 6
                }
            }
        };
    })();

}));
